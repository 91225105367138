import { useState, useCallback, useEffect, useRef } from 'react'
import { shade } from 'polished'
import Vibrant from 'node-vibrant'
import { hex } from 'wcag-contrast'
import copy from 'copy-to-clipboard'
import { SPEX_ADDRESS, TOKENS_LOGOS } from '../constants'
import { client, spexClient } from '../apollo/client'
import { SPEX_BURNED, SPEX_POL, STEPEX_FEES } from '../apollo/queries'
import { useTokenData } from '../contexts/TokenData'
import { reduceEther } from '../utils'

export function useColor(tokenAddress, token) {
  const [color, setColor] = useState('#2172E5')
  if (tokenAddress) {
    const path = TOKENS_LOGOS[tokenAddress]
    // const path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${isAddress(
    //   tokenAddress
    // )}/logo.png`
    if (path) {
      Vibrant.from(path).getPalette((err, palette) => {
        if (palette && palette.Vibrant) {
          let detectedHex = palette.Vibrant.hex
          let AAscore = hex(detectedHex, '#FFF')
          while (AAscore < 3) {
            detectedHex = shade(0.005, detectedHex)
            AAscore = hex(detectedHex, '#FFF')
          }
          if (token === 'DAI') {
            setColor('#FAAB14')
          } else {
            setColor(detectedHex)
          }
        }
      })
    }
  }
  return color
}

export function useCopyClipboard(timeout = 500) {
  const [isCopied, setIsCopied] = useState(false)

  const staticCopy = useCallback((text) => {
    const didCopy = copy(text)
    setIsCopied(didCopy)
  }, [])

  useEffect(() => {
    if (isCopied) {
      const hide = setTimeout(() => {
        setIsCopied(false)
      }, timeout)

      return () => {
        clearTimeout(hide)
      }
    }
  }, [isCopied, setIsCopied, timeout])

  return [isCopied, staticCopy]
}

export const useOutsideClick = (ref, ref2, callback) => {
  const handleClick = (e) => {
    if (ref.current && ref.current && !ref2.current) {
      callback(true)
    } else if (ref.current && !ref.current.contains(e.target) && ref2.current && !ref2.current.contains(e.target)) {
      callback(true)
    } else {
      callback(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export default function useInterval(callback: () => void, delay: null | number) {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const current = savedCallback.current
      current && current()
    }

    if (delay !== null) {
      tick()
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return
  }, [delay])
}

const circulationSupply = 120_000_000

export const useSPEXInfo = () => {
  const [spexData, setSpexData] = useState<any>()
  const tokenData = useTokenData(SPEX_ADDRESS.toLowerCase())

  const fetch = useDebounce(async () => {
    const { weekly: weeklyBurned, total: totalBurned, day: dayBurned } = await getSpexBurned()
    const stepExFees = await getStepExFees()
    // const { total: totalSpexFees, day: dayStepexFees, week: weeklyFees } = await getTotalSpexFeesCollected()

    const weeklyFeesCollected = stepExFees.weekly * 0.003
    const dayFeesCollected = stepExFees.day * 0.003
    const totalFeesCollected = stepExFees.total * 0.003

    const weeklyTreasuryPool = weeklyBurned * 2
    const dayTreasuryPool = dayBurned * 2
    const totalTreasuryPool = totalBurned * 2

    const SpexPol = await getSpexPolIncome()

    const marketCup = tokenData.priceUSD * circulationSupply

    setSpexData({
      totalStepexFees: totalFeesCollected,
      dayStepexFees: dayFeesCollected,
      weeklyFees: weeklyFeesCollected,
      weeklyBurned,
      weeklyTreasuryPool,
      totalTreasuryPool,
      totalBurned,
      dayBurned,
      SpexPol,
      marketCup,
      dayTreasuryPool,
      price: tokenData.priceUSD,
    })
  }, 200)

  useEffect(() => {
    if (!spexData) {
      fetch()
    }
    //eslint-disable-next-line
  }, [])

  return spexData
}

const getSpexBurned = async () => {
  const total = await spexClient.query({
    query: SPEX_BURNED,
    fetchPolicy: 'cache-first',
  })

  return {
    weekly: total.data.tokenDailySnapshots
      .slice(total.data.tokenDailySnapshots.length - 7, total.data.tokenDailySnapshots.length)
      .reduce((sum, current) => reduceEther(sum, current.dailyBurnAmount), 0),
    total: total.data.tokenDailySnapshots.reduce((sum, current) => reduceEther(sum, current.dailyBurnAmount), 0),
    day: reduceEther(0, total.data.tokenDailySnapshots.at(-1).dailyBurnAmount),
  }
}

const getStepExFees = async () => {
  const total = await client.query({
    query: STEPEX_FEES,
    fetchPolicy: 'cache-first',
  })
  return {
    weekly: total.data.stepExDayDatas
      .slice(total.data.stepExDayDatas.length - 7, total.data.stepExDayDatas.length)
      .reduce((sum, current) => sum + +current.dailyVolumeUSD, 0),
    day: total.data.stepExDayDatas[total.data.stepExDayDatas.length - 1].dailyVolumeUSD,
    total: total.data.stepExDayDatas.reduce((sum, current) => sum + +current.dailyVolumeUSD, 0),
  }
}

const getSpexPolIncome = async () => {
  const result = await client.query({
    query: SPEX_POL,
    fetchPolicy: 'cache-first',
  })
  return result.data.pairs[0].volumeUSD * 0.003
}

export function useDebounce(callback, delay) {
  const timer: any = useRef()

  const debouncedCallback = useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay]
  )

  return debouncedCallback
}
