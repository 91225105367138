import React from 'react'
import styled from 'styled-components'
import { RowFixed, RowBetween } from '../Row'
import { useMedia } from 'react-use'
import { useGlobalData } from '../../contexts/GlobalData'
import { formattedNum } from '../../utils'
import { StatsCard } from './Card'

import { TYPE } from '../../Theme'


const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 40;
`

const Medium = styled.span`
  font-weight: 500;
`

export default function GlobalStats() {
  const below816 = useMedia('(max-width: 816px)')

  const { oneDayTxns, txCount, oneWeekTxns, spexData } = useGlobalData()

  const ceil = (value) => {
    return Math.ceil(value)
  }

  return (
    <Header>
      <RowBetween style={{ padding: below816 ? '0.5rem 0' : '.5rem 0' }}>
        <RowFixed>
          {spexData && (
            <>
              <TYPE.main mr={'3rem'} style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Market cap,</span>  <Medium>{formattedNum(spexData.marketCup, true)}</Medium>
              </TYPE.main>
              <TYPE.main mr={'3rem'} style={{ display: 'flex', flexDirection: 'column' }}>
                <span>spex price,</span>  <Medium>{formattedNum(spexData.price, true)}</Medium>
              </TYPE.main>
              <TYPE.main mr={'3rem'} style={{ display: 'flex', flexDirection: 'column' }}>
                <span>protocol owned liquidity,</span> <TYPE.main>{formattedNum(spexData.SpexPol, true)}</TYPE.main>
              </TYPE.main>
            </>
          )}

        </RowFixed>
      </RowBetween>
      {spexData && <>
        <RowBetween style={{ flexWrap: 'wrap' }}>
          <StatsCard day={oneDayTxns} week={oneWeekTxns} total={txCount} title={'Transactions'} />
          <StatsCard day={ceil(spexData.dayStepexFees)} week={ceil(spexData.weeklyFees)} total={ceil(spexData.totalStepexFees)} title={'Fees, $'} />
        </RowBetween>
        <RowBetween style={{ flexWrap: 'wrap', marginBottom: '20px' }}>
          <StatsCard day={ceil(spexData.dayTreasuryPool)} week={ceil(spexData.weeklyTreasuryPool)} total={ceil(spexData.totalTreasuryPool)} title={'Treasury, spex'} />
          <StatsCard day={ceil(spexData.dayBurned)} week={ceil(spexData.weeklyBurned)} total={ceil(spexData.totalBurned)} title={'Burned, spex'} />
        </RowBetween>
      </>}
    </Header>
  )
}
