import React from 'react'
import { useMedia } from 'react-use'
import { TYPE } from '../../Theme'
import { formattedNum } from '../../utils'
import { RowFixed } from '../Row'
import './styles.css'

export const StatsCard = ({ title, day, week, total }) => {
  const below500 = useMedia('(max-width: 500px)')

  return (
    <div className="card">
      <h3>{title}</h3>
      <RowFixed>
        <TYPE.main mr={below500 ? '1rem' : '3rem'} style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="subtitle">24h</span>
          <span className="value">{formattedNum(day)}</span>
        </TYPE.main>
        <TYPE.main mr={below500 ? '1rem' : '3rem'} style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="subtitle">weekly</span>
          <span className="value">{formattedNum(week)}</span>
        </TYPE.main>
        <TYPE.main mr={below500 ? '1rem' : '3rem'} style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="subtitle">total</span>
          <span className="value">{formattedNum(total)}</span>
        </TYPE.main>
      </RowFixed>
    </div>
  )
}
